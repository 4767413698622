<template>
  <b-carousel-item>
    <section id="about-me" class="hero is-fullheight is-dark">
      <div class="hero-body slide-body has-text-black is-relative">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 823 684" style="enable-background:new 0 0 823 684;" xml:space="preserve" class="is-absolute"
             id="about-svg">
          <g id="about-layer">
            <g id="grid_3_" class="animate__animated animate__fadeIn _2 animate__slower">
              <g>
                <line class="red-stroke" x1="141.28" y1="598.33" x2="818.23" y2="659.37"/>
                <line class="red-stroke" x1="147.76" y1="526.41" x2="824.72" y2="587.45"/>
                <line class="red-stroke" x1="154.25" y1="454.49" x2="831.2" y2="515.53"/>
                <line class="red-stroke" x1="160.73" y1="382.58" x2="837.69" y2="443.62"/>
                <line class="red-stroke" x1="167.22" y1="310.66" x2="844.17" y2="371.7"/>
                <line class="red-stroke" x1="173.7" y1="238.74" x2="850.65" y2="299.78"/>
              </g>
              <g>
                <line class="red-stroke" x1="228.4" y1="192.33" x2="187.73" y2="643.38"/>
                <line class="red-stroke" x1="300.32" y1="198.82" x2="259.65" y2="649.87"/>
                <line class="red-stroke" x1="372.24" y1="205.3" x2="331.57" y2="656.35"/>
                <line class="red-stroke" x1="444.15" y1="211.79" x2="403.48" y2="662.84"/>
                <line class="red-stroke" x1="516.07" y1="218.27" x2="475.4" y2="669.32"/>
                <g>
                  <line class="red-stroke" x1="590.6" y1="224.99" x2="549.93" y2="676.04"/>
                  <line class="red-stroke" x1="662.52" y1="231.48" x2="621.85" y2="682.52"/>
                  <line class="red-stroke" x1="734.43" y1="237.96" x2="693.76" y2="689.01"/>
                  <line class="red-stroke" x1="806.35" y1="244.45" x2="765.68" y2="695.49"/>
                </g>
              </g>
            </g>
            <g id="triangle_4_" class="animate__animated animate__fadeInUp _3 ">
              <polygon class="black" points="258.82,39.03 235.11,119.77 289.11,114.5 		"/>
              <polygon class="red black-stroke small-stroke" points="249.6,32.44 225.89,113.18 279.89,107.92 		"/>
            </g>
            <g id="right" class="animate__animated animate__fadeInTopRight _2">
              <polygon class="black" points="346.01,597.2 803.43,597.2 803.43,25.72 548.54,25.72 		"/>
              <g>
                <polygon class="white" points="332.73,575.49 533.83,8.01 785.31,8.01 785.31,575.49 			"/>
                <g>
                  <path class="black" d="M783.31,10.01v563.48H335.56L535.24,10.01H783.31 M787.31,6.01h-254.9L329.9,577.49h457.42V6.01
                    L787.31,6.01z"/>
                </g>
              </g>
              <polygon class="no-fill"
                       points="548.81,32.86 763.62,32.86 763.62,366.25 763.62,550.64 591.1,550.64 368.42,550.64 		"/>
              <text transform="matrix(1 0 0 1 548.3309 46.6602)"><tspan x="0" y="0" class="lufga svg-text-18">I strive for all of the </tspan>
                <tspan x="-7.88" y="22.63" class="lufga svg-text-18">products I create to be </tspan>
                <tspan x="-15.77" y="45.25" class="lufga svg-text-18">innovative and accessible </tspan>
                <tspan x="-23.65" y="67.88" class="lufga svg-text-18">in an out-of-the-div way </tspan>
                <tspan x="-31.53" y="90.51" class="lufga svg-text-18">and I hope that the lasting </tspan>
                <tspan x="-39.41" y="113.13" class="lufga svg-text-18">mark I make on my small </tspan>
                <tspan x="-47.3" y="135.76" class="lufga svg-text-18">corner of the web reflects </tspan>
                <tspan x="-55.18" y="158.38" class="lufga svg-text-18">that goal. My current favorite </tspan>
                <tspan x="-63.06" y="181.01" class="lufga svg-text-18">frameworks are</tspan>
                <tspan x="145.06" y="181.01" class="lufga svg-text-18">and</tspan>
                <tspan x="179.01" y="181.01" class="lufga svg-text-18 st22">	</tspan>
                <tspan x="193.42" y="181.01" class="lufga svg-text-18 st23">	</tspan>
                <tspan x="-70.95" y="203.64" class="lufga svg-text-18"> </tspan>
                <tspan x="-64.5" y="203.64" class="lufga svg-text-18 st24">	</tspan>
                <tspan x="-44.16" y="203.64" class="lufga svg-text-18 st23">	</tspan>
                <tspan x="-10.22" y="203.64" class="lufga svg-text-18">for CSS. This site uses </tspan>
                <tspan x="196.87" y="203.64" class="lufga svg-text-18 st25">	</tspan>
                <tspan x="-78.83" y="226.26" class="lufga svg-text-18 st26">	</tspan>
                <tspan x="-78.09" y="226.26" class="lufga svg-text-18 st23">	</tspan>
                <tspan x="-30.16" y="226.26" class="lufga svg-text-18">, a convenient combination </tspan>
                <tspan x="-86.71" y="248.89" class="lufga svg-text-18">of the two.</tspan>
                <tspan x="-94.59" y="271.52" class="lufga svg-text-18">I try to reinvent my personal </tspan>
                <tspan x="-102.48" y="294.14" class="lufga svg-text-18">website around once a year, to </tspan>
                <tspan x="-110.36" y="316.77" class="lufga svg-text-18">accommodate changes in my </tspan>
                <tspan x="-118.24" y="339.4" class="lufga svg-text-18">personal aesthetic tastes, stay </tspan>
                <tspan x="-126.13" y="362.02" class="lufga svg-text-18">current on design trends, and to </tspan>
                <tspan x="-134.01" y="384.65" class="lufga svg-text-18">challenge myself to incorporate a new </tspan>
                <tspan x="-141.89" y="407.28"
                       class="lufga svg-text-18">frontend feature that requires skills I </tspan>
                <tspan x="-149.78" y="429.9"
                       class="lufga svg-text-18">don&apos;t have yet. In this iteration of my </tspan>
                <tspan x="-157.66" y="452.53"
                       class="lufga svg-text-18">portfolio, I wanted to reimagine what a </tspan>
                <tspan x="-165.54" y="475.15"
                       class="lufga svg-text-18">showcase could look outside of a single </tspan>
                <tspan x="-173.42" y="497.78" class="lufga svg-text-18">page scroller layout.</tspan></text>
              <a xlink:href="https://vuejs.org/v2/guide/" target="_blank" class="svg-link"><text transform="matrix(1 0 0 1 630.994 227.9704)" class="lufga svg-text-18">Vue.js</text></a>
              <a xlink:href="https://bulma.io/documentation/" target="_blank" class="svg-link"><text transform="matrix(1 0 0 1 476.1561 250.3377)" class="lufga svg-text-18">Bulma</text></a>
              <a xlink:href="https://buefy.org/documentation/" target="_blank" class="svg-link"><text transform="matrix(1 0 0 1 468.7166 273)" class="lufga svg-text-18">Buefy</text></a>
            </g>
            <g id="left" class="animate__animated animate__fadeInBottomLeft _2">
              <polygon class="black" points="483.49,97.58 26.07,97.58 26.07,669.06 280.97,669.06 		"/>
              <g>
                <polygon class="blue" points="16.14,650.38 16.14,82.9 468.73,82.9 267.63,650.38 			"/>
                <path class="black"
                      d="M465.9,84.9L266.21,648.38H18.14V84.9H465.9 M471.56,80.9H14.14v571.48h254.9L471.56,80.9L471.56,80.9z"/>
              </g>
              <polygon class="no-fill"
                       points="34.25,108.11 34.25,446.57 300.33,446.57 377.53,246.42 179.28,108.11 		"/>
              <text transform="matrix(1 0 0 1 34.2455 121.9166)"><tspan x="0" y="0" class="lufga svg-text-18">Born and raised </tspan>
                <tspan x="0" y="22.63" class="lufga svg-text-18">in Miami Beach by </tspan>
                <tspan x="0" y="45.25" class="lufga svg-text-18">parents in </tspan>
                <tspan x="0" y="67.88" class="lufga svg-text-18">tech-adjacent fields, while </tspan>
                <tspan x="0" y="90.51" class="lufga svg-text-18">growing up I naturally </tspan>
                <tspan x="0" y="113.13" class="lufga svg-text-18">gravitated toward computers. I </tspan>
                <tspan x="0" y="135.76" class="lufga svg-text-18">started designing t-shirts in MS Paint </tspan>
                <tspan x="0" y="158.38" class="lufga svg-text-18">while in elementary school. </tspan>
                <tspan x="0" y="181.01" class="lufga svg-text-18">Throughout high school and college, </tspan>
                <tspan x="0" y="203.64" class="lufga svg-text-18">I continued to cultivate these </tspan>
                <tspan x="0" y="226.26" class="lufga svg-text-18">skills, mostly through freelance </tspan>
                <tspan x="0" y="248.89" class="lufga svg-text-18">jobs designing logos, websites, </tspan>
                <tspan x="0" y="271.52" class="lufga svg-text-18">and app mockups.</tspan></text>
            </g>
            <g id="avi" class="animate__animated animate__fadeInUp _3 ">
              <polygon class="white" points="29.29,641.75 30.12,639.34 41.95,648.12 30.82,648.03 		"/>
              <path class="white" d="M144.42,648.04h42.56l-1.15-24.77l-17.62-26.24c0,0-4.53-5.08-13.35-6.24c-8.83-1.16-7.55-6.75-7.55-6.75
                s1.67-4.11,1.21-3.29s-0.5,0.93-2.63,2.52c-2.14,1.59-2.84,8.74-2.84,8.74L144.42,648.04z"/>
              <path class="nude" d="M189.82,648c-2.18,0.01-4.35,0.02-6.53,0.02c2.47-1.17,4.96-2.33,7.42-3.53c0.87-0.42,1.87-0.87,0.78-2.3
                c-3.83,0.7-6.85,4.41-11.41,3.68c0.08-1.55,0.13-2.85,0.92-4.02c3.14-1.99,6.7-3.29,9.41-6.11c-4.27-0.17-7.14,3.39-10.92,4.32
                c-0.82-0.87-0.72-1.72-0.42-2.76c4.41-3.65,8.61-7.79,14.15-9.79c1.58,0.28,2.59,1.21,3.59,2.06c3.74,3.17,7.48,6.36,11.15,9.61
                c3.16,2.8,6.57,5.36,9.06,8.85c-8.07,0.02-16.14,0.04-24.21,0.07C192.16,646.56,192.16,646.56,189.82,648z M197.15,634.4
                c-0.01,4.24,0.2,8.48,0.32,12.72C198.21,642.86,198.45,638.61,197.15,634.4z"/>
              <path class="white" d="M97.99,648.11c0-3.18,0-6.35,0-9.65c10.59,0,21.25,0,32.01,0c0,3.25,0,6.41,0,9.58
                C119.33,648.05,108.66,648.08,97.99,648.11z"/>
              <path class="grey" d="M149.43,597.27c-0.68,2.89-0.16,5.93-1.14,8.84c-1.98,1.15-4.04,1.13-6.33,0.51
                c-0.01-6.94-0.68-13.78-1.06-20.6c1.27-0.73,2.43-0.66,3.61-0.44c-0.15,0.86-0.32,1.72-0.46,2.58
                C143.27,592.71,145.42,595.54,149.43,597.27z"/>
              <path class="grey" d="M66.66,648.1c2.71-2.15,5.72-2.91,9.22-2.78c6.5,0.24,13.01,0.07,19.64,0.07c0.1,0.99,0.19,1.85,0.28,2.71
                C86.09,648.09,76.37,648.1,66.66,648.1z"/>
              <path class="grey" d="M132.36,648.08c0.05-0.94,0.1-1.88,0.16-2.93c4.2,0,8.29,0,12.57,0c0,1.02,0,1.96,0,2.91
                C140.85,648.07,136.6,648.07,132.36,648.08z"/>
              <path class="black" d="M151.19,588.7c1.03,0.95,2.91,0.46,3.68,2.09c-3.84-0.24-8.1-2.44-7.55-6.75
                C149.37,584.18,149.37,584.18,151.19,588.7z"/>
              <path class="black"
                    d="M189.82,648c2.34-1.44,2.34-1.44,2.98,0.11C191.81,648.07,190.81,648.04,189.82,648z"/>
              <path class="brown" d="M144.72,451.55c-0.05,2.04,4.58,4.94,10.59,6.69c4.32,1.26,8.61,1.23,12.78-0.3
                c6.85,8.82,4.6,29.47-2.17,38.52c-0.57-8.84-1.36-17.27-7.59-23.84c-1.5,0.66-1.61,1.54-0.89,2.45c1.43,1.81,1.5,3.71,0.95,5.87
                c-1.43,5.67-2.41,11.49-5.7,16.52c-0.32,0.49-0.69,0.96-1.09,1.4c-0.28,0.32-0.64,0.56-1.37,1.18c1.77-7.74,0.94-14.86-1.25-21.86
                c-1.98-6.3-5.02-12.01-9.46-16.98c-3.23-3.61-6.54-7.09-10.26-10.2c-2.55-2.13-4.92-2.87-8.23-1.06
                c-11.98,6.56-20.21,15.93-23.21,29.43c-1.14,5.14-0.69,10.46-1.37,16.18c-4.36-5.99-5.62-12.99-8.56-18.95
                c-1.74-0.76-2.29,0.21-2.73,1.32c-1.28,3.21-1.55,6.62-1.87,10.01c-0.41,4.33-0.53,8.67,0.18,13.05
                c-3.71-5.9-4.44-13.33-9.32-18.45c-1.53,0.15-1.78,0.98-2.12,1.97c-2.09,6.24-4.8,12.15-9.61,16.84c-0.2,0.2-0.45,0.36-0.95,0.76
                c1.08-8.91-3.44-16.55-4.37-24.81c-0.95-8.45-0.08-16.87,2.21-25.11c1.5-5.41,3.44-10.62,7.24-14.89
                c4.94-5.54,11.11-9.07,18.39-10.55c7.99-1.62,15.48,0.17,22.72,3.49c0.96,0.44,1.91,0.87,2.71,0.33c1.02-3.84,3.65-6.23,6.45-8.25
                c9.41-6.78,19.93-4.41,28.68,2.08c4.44,3.29,7.54,8.1,12.14,11.14c6.91,4.56,14.31,7.29,23.06,3.9
                c-1.35,3.98-2.98,7.63-5.73,10.49c-4.41,4.6-9.52,7.62-16.44,6.4c-3.94-0.69-7.22-2.66-10.72-4.25
                C146.83,451.62,145.89,450.86,144.72,451.55z"/>
              <path class="nude" d="M146.6,480.88c-6.41,0.95-10.88,3.72-10.11,6.58c3.76-1.17,6.78-5,11.32-3.62c0.59,2.95,1.01,5.71,0.81,8.81
                c-4.47-1.13-8.82-2.88-12.79,0.8c-1.72,1.6-1.93,1.65-2.29,3.78c3.55,0.04,5.18-4.92,9.04-3.31c-1.1,1.72-1.7,3.61-0.11,5.43
                c1.33,1.52,3.02,1.81,4.89,0.88c0.61,0.98-0.35,2.47,1.52,3.05c1.55-0.01,2.86-1.29,4.57-2.5c0.63,4.55,0.61,8.88,0.24,13.2
                c-0.69,7.99-3.36,15.5-5.71,23.08c-1.34,4.32-2.61,8.67-3.94,13c-1.72,5.61-2.97,6.66-8.88,6.67c-6.01,0.02-11.8-1.41-17.43-3.43
                c-9.79-3.52-18.63-8.86-27.53-14.14c-5.41-3.21-7.4-8-7.84-13.88c-0.18-2.37-0.63-4.71-0.96-7.06c-0.13-0.92-0.43-1.73-1.31-2.17
                c-6.19,0.79-9.85-3.48-9.33-11.17c1.1,0.91,1.81,2.15,3.16,2.41c1.4,0.27,2.62-0.21,3.89-1.11c0-3.26,0-6.58,0-9.9
                c1.99,3.76,3.32,7.97,7.46,9.93c1.59-0.48,1.54-1.44,1.3-2.56c-1.43-6.86-1.33-13.74-0.18-20.62c0.1-0.57,0.26-1.13,0.54-2.29
                c2.32,5.92,3.93,11.54,7.15,16.5c0.99,0.62,1.7,2.1,2.94,2.25c3.62-2.1,6.87-4.36,11.06-5.72c-0.34,1.62-0.94,2.85-0.37,4.18
                c0.83,1.94,2.21,3,4.3,2.87c2.09-0.13,3.53-1.35,3.83-3.41c0.16-1.06,0.62-1.71,1.35-2.23c0.09-1.41-0.85-1.93-1.78-2.39
                c-3.84-1.89-7.68-2.06-11.57-0.06c-1.49,0.77-2.77,1.98-4.34,2.28c0-3.61,0-7.15,0-10.45c5.38-3.82,10.56-3.69,15.89-0.94
                c1.8,0.93,1.84,0.85,3.03-0.13c-1.98-4.04-9.93-5.15-18.08-2.53c-0.37-10.44,14.19-27.6,25.39-29.64
                c9.44,7.91,18.18,16.48,21.29,29.21C146.86,480.64,146.73,480.76,146.6,480.88z M124.06,521.47c2.17,2.01,4.99,2.2,7.32,0.83
                c2.62-1.54,3.2-3.29,2.12-6.14c-0.68-1.8-1.29-3.62-1.89-5.45c-0.99-3.02-0.94-6.24-1.59-9.31c-1.94,0.15-1.93,0.18-1.83,1.95
                c0.29,5.31,1.85,10.31,3.78,14.95C130.57,522.42,126.64,519.92,124.06,521.47z M115.54,533c5.62,6.15,13.7,10.08,22.16,0.93
                c-3.99,0.37-6.93-4.8-11.16-1.76c-1.83-1.29-3.65-1.51-5.45-0.68C119.34,532.28,117.69,533.31,115.54,533z"/>
              <path class="black" d="M217.74,645.58c-6.27-6.59-13.5-12.1-20.3-18.1c-1.54-1.36-3.29-2.26-5.42-2.04
                c-2.42-3.22-4.76-6.37-6.5-9.93c-1.83-3.74-3.95-7.32-6.77-10.42c-2.92-3.23-6.38-5.74-10.53-8.05c0.61,1.73,0.41,1.62,1.56,4.36
                c1.15,2.74-0.44,12.95-0.54,13.22c-0.06,0.3-0.12,0.61-0.18,0.91c-0.13,0.16-0.26,0.33-0.39,0.49c-0.8-0.04-1.61-0.03-2.41-0.13
                c-3.74-0.45-7.47-0.61-11.21-0.11c1.21-2.62,2.03-5.37,2.86-8.13c1.5-5.01-0.3-8.24-5.31-9.52c-1.06-0.27-2.11-0.58-3.16-0.87
                c-0.68,2.89-0.16,5.93-1.14,8.84c-1.98,1.15-4.04,1.13-6.33,0.51c-0.01-6.94-0.68-13.78-1.06-20.6c1.27-0.73,2.43-0.66,3.6-0.44
                c0.41-0.69,0.82-1.37,1.38-2.32c-2.71-0.45-5.06-0.61-7.12,1.22c-0.13,0.7-0.25,1.32-0.39,2.05c-1.71-0.33-3.33-0.54-4.89-0.96
                c-6.7-1.81-13.14-4.43-15.77-11.42c-2.24-5.97-1.68-12.33-0.29-18.68c0.73,0.22,1.29,0.4,1.86,0.55
                c5.31,1.46,10.65,2.88,16.21,2.78c6.48-0.12,8.38-1.53,10.32-7.65c2-6.29,3.8-12.64,5.85-18.92c3.35-10.28,5.25-20.72,3.98-31.58
                c-0.12-1.02-0.33-2.24,0.09-3.08c2.47-4.92,3.81-10.2,4.99-15.55c0.75,0.29,0.69,0.88,0.83,1.36c1.38,4.61,1.65,9.35,1.72,14.11
                c0.02,1.16-0.16,2.41,0.97,3.23c2.44-0.56,4.02-2.06,5.68-5.77c5.66-12.65,7.36-25.39,0.63-38.38
                c7.48-4.04,10.89-11.12,13.44-18.82c0.35-1.06,0.58-2.05-0.77-2.53c-0.94,0-1.54,0.63-2.23,1.04c-3.06,1.8-6.36,2.66-9.85,2.02
                c-5.8-1.06-11.1-3.21-15.34-7.57c-2.75-2.83-5.47-5.75-8.59-8.12c-7.99-6.07-16.77-8.97-26.72-5.19
                c-4.8,1.82-8.54,5.04-11.06,9.02c-2.74,1.36-4.73-0.08-6.74-0.85c-16.71-6.4-38.31,2.82-44.28,20.98
                c-4.51,13.73-5.97,27.49-1.34,41.55c1.81,5.49,3.43,11.16,0.47,16.89c-0.53,1.03,0.12,1.67,1.23,1.94
                c3.95-1.62,6.58-4.84,9.55-8.48c0.07,1.69,1.17,2.54,0.73,3.98c-1.31,4.36-0.14,8.38,2.09,12.14c1.79,3.03,4.27,4.87,8.02,3.83
                c2.07,6.92,0.19,15.01,6.93,20.21c-1.57,8.36-1.51,16.8-2.9,25.09c-0.95,5.7-2.32,11.21-6.42,15.36
                c-4.14,0.89-7.78,2.47-11.12,4.22c-0.65,1.48-0.05,2.56,0.43,3.76c-5.24,2.74-10.35,5.42-15.47,8.09
                c-6.84,3.56-11.38,8.99-13.33,16.45c-1.67,6.37-4.18,12.38-7.53,18.01c-3.75,6.3-2.41,12.35,0.22,18.43c0.57,0,1.07,0,1.55,0
                c0.36,0,0.71,0,1.07,0c-0.81-2.39-2.32-4.46-2.14-7.03c2.31,2.3,4.71,4.48,7.37,6.38c0.62,0.44,1.18,0.76,1.99,0.75
                c0.71-0.01,1.43-0.01,2.14-0.02c6.91-0.05,13.82-0.06,20.73-0.09c1.33,0.02,2.65,0.05,3.98,0.07c2.71-2.15,5.72-2.91,9.22-2.78
                c6.5,0.24,13.01,0.07,19.64,0.07c0.1,0.99,0.19,1.85,0.28,2.71c0.73,0,1.46,0.01,2.19,0.01c0-3.18,0-6.35,0-9.65
                c10.59,0,21.25,0,32.01,0c0,3.25,0,6.41,0,9.58c0.79,0.02,1.58,0.04,2.36,0.05c0.05-0.94,0.1-1.88,0.16-2.93c4.2,0,8.29,0,12.57,0
                c0,1.02,0,1.96,0,2.91c0.65,0.01,1.31,0.23,2.03-0.2c0-8.34,0-16.72,0-25.02c2.12-2.39,4.87-3.53,7.6-4.69c0-0.2,0-0.2,0,0
                c4.76-1.49,9.56-1.47,14.39-0.43c3.68,0.93,7.19,2.22,10.17,4.41c0.47,1.72-0.35,3.42,0.62,5.02c1.87,0.39,4.38-0.28,4.95,2.51
                c-0.61,1.41-1.6,0.68-2.49,0.62c-0.95-0.06-1.95-0.19-2.88,0.78c0,1,0,2.17,0,3.16c-3.22,3.5-3.34,4.83-0.8,8.01
                c-0.54,2.07-1.08,4.19,1.02,5.79c1.22,0,2.41,0,3.59,0c2.47-1.17,4.96-2.33,7.42-3.53c0.87-0.42,1.87-0.87,0.78-2.3
                c-3.83,0.7-6.85,4.41-11.41,3.68c0.08-1.55,0.13-2.85,0.92-4.02c3.14-1.99,6.7-3.29,9.41-6.11c-4.27-0.17-7.14,3.39-10.92,4.32
                c-0.82-0.87-0.72-1.72-0.42-2.76c4.41-3.65,8.61-7.79,14.15-9.79c1.58,0.28,2.59,1.21,3.59,2.06c3.74,3.17,7.48,6.36,11.15,9.61
                c3.16,2.8,6.57,5.36,9.06,8.85c0.84,0,1.67,0,2.85,0C218.98,647.02,218.4,646.27,217.74,645.58z M143.44,611.31
                c2,0,2.93,1.18,2.9,2.73c0.01,2.53-1.2,3.75-3.29,3.71c-1.88-0.03-3.42-1.58-3.3-3.45C139.9,612.05,141.47,611.31,143.44,611.31z
                 M86.54,616.79c-0.71,1.69-2.2,2.23-3.86,1.74c-1.99-0.58-2.76-2.07-2.42-4.3c1.71-1.56,3.48-2.61,5.64-1.23
                C87.36,613.93,87.1,615.45,86.54,616.79z M62.4,501.34c-0.2,0.2-0.45,0.36-0.95,0.76c1.08-8.91-3.44-16.55-4.37-24.81
                c-0.95-8.45-0.08-16.87,2.21-25.11c1.5-5.41,3.44-10.62,7.24-14.89c4.94-5.54,11.11-9.07,18.39-10.55
                c7.99-1.62,15.48,0.17,22.72,3.49c0.96,0.44,1.91,0.87,2.71,0.33c1.02-3.84,3.65-6.23,6.45-8.25c9.41-6.78,19.93-4.41,28.68,2.08
                c4.44,3.29,7.54,8.1,12.14,11.14c6.91,4.56,14.31,7.29,23.06,3.9c-1.35,3.98-2.98,7.63-5.73,10.49c-4.41,4.6-9.52,7.62-16.44,6.4
                c-3.94-0.69-7.22-2.66-10.72-4.25c-0.99-0.45-1.93-1.21-3.1-0.52c-0.05,2.04,4.58,4.94,10.59,6.69c4.32,1.26,8.61,1.23,12.78-0.3
                c6.85,8.82,4.6,29.47-2.17,38.52c-0.57-8.84-1.36-17.27-7.59-23.84c-1.5,0.66-1.61,1.54-0.89,2.45c1.43,1.81,1.5,3.71,0.95,5.87
                c-1.43,5.67-2.41,11.49-5.7,16.52c-0.32,0.49-0.69,0.96-1.09,1.4c-0.28,0.32-0.64,0.56-1.37,1.18c1.77-7.74,0.94-14.86-1.25-21.86
                c-1.98-6.3-5.02-12.01-9.46-16.98c-3.23-3.61-6.54-7.09-10.26-10.2c-2.55-2.13-4.92-2.87-8.23-1.06
                c-11.98,6.56-20.21,15.93-23.21,29.43c-1.14,5.14-0.69,10.46-1.37,16.18c-4.36-5.99-5.62-12.99-8.56-18.95
                c-1.74-0.76-2.29,0.21-2.73,1.32c-1.28,3.21-1.55,6.62-1.87,10.01c-0.41,4.33-0.53,8.67,0.18,13.05
                c-3.71-5.9-4.44-13.33-9.32-18.45c-1.53,0.15-1.78,0.98-2.12,1.97C69.92,490.74,67.22,496.65,62.4,501.34z M73.77,487.09
                c1.39,1.38,2.15,15.96,0.94,18c-2.54-1.97-3.31-4.71-4.12-7.32C69.3,493.58,73.25,490.96,73.77,487.09z M82.36,525.27
                c-0.18-2.37-0.63-4.71-0.96-7.06c-0.13-0.92-0.43-1.73-1.31-2.17c-6.19,0.79-9.85-3.48-9.33-11.17c1.1,0.91,1.81,2.15,3.16,2.41
                c1.4,0.27,2.62-0.21,3.89-1.11c0-3.26,0-6.58,0-9.9c1.99,3.76,3.32,7.97,7.46,9.93c1.59-0.48,1.54-1.44,1.3-2.56
                c-1.43-6.86-1.33-13.74-0.18-20.62c0.1-0.57,0.26-1.13,0.54-2.29c2.32,5.92,3.93,11.54,7.15,16.5c0.99,0.62,1.7,2.1,2.94,2.25
                c3.62-2.1,6.87-4.36,11.06-5.72c-0.34,1.62-0.94,2.85-0.37,4.18c0.83,1.94,2.21,3,4.3,2.87c2.09-0.13,3.53-1.35,3.83-3.41
                c0.16-1.06,0.62-1.71,1.35-2.23c0.09-1.41-0.85-1.93-1.78-2.39c-3.84-1.89-7.68-2.06-11.57-0.06c-1.49,0.77-2.77,1.98-4.34,2.28
                c0-3.61,0-7.15,0-10.45c5.38-3.82,10.56-3.69,15.89-0.94c1.8,0.93,1.84,0.85,3.03-0.14c-1.98-4.04-9.93-5.15-18.08-2.53
                c-0.37-10.44,14.19-27.6,25.39-29.64c9.44,7.91,18.18,16.48,21.29,29.21c-0.02,0.17-0.05,0.34-0.07,0.51
                c-0.11-0.05-0.22-0.1-0.33-0.15c-6.41,0.95-10.89,3.72-10.11,6.58c3.76-1.17,6.78-5,11.32-3.62c0.59,2.95,1.01,5.71,0.81,8.81
                c-4.47-1.13-8.82-2.88-12.79,0.8c-1.72,1.6-1.93,1.65-2.29,3.78c3.55,0.04,5.18-4.92,9.04-3.31c-1.1,1.72-1.7,3.61-0.11,5.43
                c1.33,1.52,3.02,1.81,4.89,0.88c0.61,0.98-0.35,2.47,1.52,3.05c1.55-0.01,2.86-1.29,4.57-2.5c0.63,4.55,0.61,8.88,0.24,13.2
                c-0.69,7.99-3.36,15.5-5.71,23.08c-1.34,4.32-2.61,8.67-3.94,13c-1.72,5.61-2.97,6.66-8.88,6.67c-6.01,0.02-11.8-1.41-17.43-3.43
                c-9.79-3.52-18.63-8.86-27.53-14.14C84.79,535.93,82.8,531.14,82.36,525.27z M87.91,540.09c8.83,5.31,17.42,10.75,27.17,14.28
                c-0.81,6.77-1.21,13.43,0.08,20.02c-7.73,9.73-23.45,9.05-33.07,1.07C86.95,564.24,86.04,552.05,87.91,540.09z M80.85,577.82
                c12.11,7.62,23.96,9.39,35.62-0.87c0.66,0.92,1.33,1.86,1.98,2.77c-7.15,9.76-30.46,11.13-39.66,0.75
                C79.48,579.58,80.19,578.68,80.85,577.82z M67.83,584.94c2.49-1.29,4.89-2.2,7.76-3.1c2.15,8.05,3.24,16.37,7.63,23.53
                c-1.59,2.7-4.21,2.97-6.7,2.83c-2.07-0.12-2.72-2.18-3.14-3.9c-0.84-3.45-1.45-6.96-2.21-10.44
                C70.5,590.75,69.71,587.68,67.83,584.94z M95.8,631.82c-0.86,4.28-0.24,8.22-0.43,12.27c-7.62,0-15.16,0-22.81,0
                c7.08-8.96,4.77-19.21,4.31-29.17c0.35-0.09,0.55-0.18,0.76-0.19c0.44-0.02,0.88-0.01,1.29-0.01c0.84,3.53,2.23,4.92,4.82,4.87
                c2.43-0.05,3.55-1.28,4.36-5c8.1,0,16.26,0,24.77,0c0,5.55,0,11.23,0,17.22C107.4,631.82,101.71,631.82,95.8,631.82z
                 M129.94,637.23c-10.66,0-21.32,0-31.95,0c-0.47-0.86-0.33-1.66-0.28-2.69c10.68,0,21.33,0,32.19,0
                C130.2,635.39,130.17,636.24,129.94,637.23z M145.05,621.58c0,7.42,0,14.82,0,22.35c-4.24,0-8.3,0-12.57,0c0-3.88,0-7.71,0-11.93
                c-6.07,0-11.95,0-18.13,0c0-6.07,0-11.76,0-17.67c7.91,0,16,0,24.13,0c1.55,3.7,2.71,4.68,5.32,4.32
                c2.36-0.33,3.46-1.82,3.54-4.16c0.71-0.44,1.45-0.7,2.4-0.43c0.13,1.22,0.26,2.45,0.41,3.82
                C148.55,619.05,146.94,620.21,145.05,621.58z"/>
              <path class="nude" d="M115.16,574.39c-7.73,9.73-23.45,9.05-33.07,1.07c4.86-11.22,3.95-23.42,5.82-35.38
                c8.83,5.31,17.42,10.75,27.17,14.28C114.28,561.14,113.88,567.8,115.16,574.39z"/>
              <path class="grey" d="M95.8,631.82c-0.86,4.28-0.24,8.22-0.43,12.27c-7.62,0-15.16,0-22.81,0c7.08-8.96,4.77-19.21,4.31-29.17
                c0.35-0.09,0.55-0.18,0.76-0.19c0.44-0.02,0.88-0.01,1.29-0.01c0.84,3.53,2.23,4.92,4.82,4.87c2.43-0.05,3.55-1.28,4.36-5
                c8.1,0,16.26,0,24.77,0c0,5.55,0,11.23,0,17.22C107.4,631.82,101.71,631.82,95.8,631.82z"/>
              <path class="grey" d="M145.05,621.58c0,7.42,0,14.82,0,22.35c-4.24,0-8.3,0-12.57,0c0-3.88,0-7.71,0-11.93
                c-6.07,0-11.95,0-18.13,0c0-6.07,0-11.76,0-17.67c7.91,0,16,0,24.13,0c1.55,3.7,2.71,4.68,5.32,4.32
                c2.36-0.33,3.46-1.82,3.54-4.16c0.71-0.44,1.45-0.7,2.4-0.43c0.13,1.22,0.26,2.45,0.41,3.82
                C148.55,619.05,146.94,620.21,145.05,621.58z"/>
              <path class="grey" d="M67.83,584.94c2.49-1.29,4.89-2.2,7.76-3.1c2.15,8.05,3.24,16.37,7.63,23.53c-1.59,2.7-4.21,2.97-6.7,2.83
                c-2.07-0.12-2.72-2.18-3.14-3.9c-0.84-3.45-1.45-6.96-2.21-10.44C70.5,590.75,69.71,587.68,67.83,584.94z"/>
              <path class="white" d="M116.47,576.96c0.66,0.92,1.33,1.86,1.98,2.77c-7.15,9.76-30.46,11.13-39.66,0.75
                c0.69-0.89,1.4-1.8,2.06-2.65C92.96,585.44,104.81,587.21,116.47,576.96z"/>
              <path class="blue" d="M129.9,634.54c0.29,0.85,0.27,1.7,0.03,2.69c-10.66,0-21.32,0-31.95,0c-0.47-0.86-0.33-1.66-0.28-2.69
                C108.39,634.54,119.04,634.54,129.9,634.54z"/>
              <path class="brown" d="M74.7,505.1c-2.54-1.97-3.31-4.71-4.12-7.32c-1.29-4.2,2.66-6.82,3.18-10.68
                C75.16,488.47,75.91,503.06,74.7,505.1z"/>
              <path class="white" d="M146.34,614.04c0.01,2.53-1.2,3.75-3.29,3.71c-1.88-0.03-3.42-1.58-3.3-3.45c0.15-2.25,1.72-2.99,3.68-2.99
                C145.44,611.31,146.36,612.49,146.34,614.04z"/>
              <path class="white" d="M80.25,614.23c1.71-1.56,3.48-2.61,5.64-1.23c1.47,0.94,1.21,2.46,0.65,3.79c-0.71,1.69-2.2,2.23-3.86,1.74
                C80.69,617.94,79.92,616.45,80.25,614.23z"/>
              <path class="nude" d="M147,480.53c-0.02,0.17-0.05,0.34-0.07,0.51c-0.11-0.05-0.22-0.1-0.33-0.15
                C146.73,480.76,146.86,480.64,147,480.53z"/>
              <path class="black"
                    d="M197.15,634.4c1.3,4.21,1.06,8.46,0.32,12.72C197.35,642.88,197.15,638.65,197.15,634.4z"/>
              <path class="white" d="M154.48,620.54c5.49-0.6,10.98-0.7,16.46,0.11c1.49,0.62,3.25,0.67,4.57,1.98c-1.06,0.86-2.2,0.51-3.24,0.64
                c-0.14-0.41-0.49-0.51-0.86-0.58c-6.03-1.17-12.04-1.18-18.02,0.32c-0.84,0.18-1.48-0.2-2.35-0.8
                C152.33,621.59,153.41,621.06,154.48,620.54z"/>
              <path class="brown" d="M172.37,623.54c-3.87,2.04-8.03,1.35-12.11,1.17c-1.53-0.07-3.04-0.56-4.56-0.85
                c3.27-1.37,6.68-1.35,10.13-1C168.01,623.08,170.19,623.31,172.37,623.54z"/>
              <path class="black" d="M170.88,619.97c-5.52-1.18-11.03-1.25-16.55,0c0.05,0.19,0.1,0.39,0.15,0.58c5.49-0.6,10.98-0.7,16.46,0.11
                c0,0,0,0,0,0c1.49,0.62,3.25,0.67,4.57,1.98c-1.06,0.86-2.2,0.51-3.24,0.64l0.07,0.05c-0.03-0.03-0.07-0.05-0.07-0.05
                c-0.14-0.41-0.49-0.51-0.86-0.58c-6.03-1.17-12.04-1.18-18.02,0.32c-0.84,0.18-1.48-0.2-2.35-0.8c1.3-0.63,2.37-1.16,3.45-1.68
                c-0.05-0.19-0.1-0.39-0.15-0.58c-1.79,0.22-3.42,0.73-4.59,2.21c4.02,4.76,22.73,5.07,27.46,0.45
                C175.47,620.79,173.34,620,170.88,619.97z M160.26,624.72c-1.53-0.07-3.04-0.56-4.56-0.85c3.27-1.37,6.68-1.35,10.13-1
                c2.18,0.22,4.36,0.45,6.54,0.68C168.49,625.58,164.34,624.9,160.26,624.72z"/>
              <path class="black" d="M124.06,521.47c2.59-1.55,6.51,0.94,7.92-3.18c-1.94-4.64-3.49-9.64-3.78-14.95c-0.1-1.76-0.1-1.8,1.83-1.95
                c0.64,3.07,0.6,6.29,1.59,9.31c0.6,1.83,1.21,3.65,1.89,5.45c1.07,2.85,0.49,4.6-2.12,6.14
                C129.05,523.67,126.22,523.49,124.06,521.47z"/>
              <path class="black" d="M115.54,533c2.15,0.31,3.8-0.71,5.55-1.52c1.8-0.83,3.62-0.61,5.45,0.68c4.23-3.04,7.17,2.13,11.16,1.76
                C129.24,543.08,121.16,539.15,115.54,533z M119.16,534.76c4.03,4.5,12.65,4.68,15.19,0.37
                C129.26,535.98,124.29,535.82,119.16,534.76z M133.57,534.15c-1.8-1.7-3.69-2.04-5.73-1.48c-0.89,0.24-1.67,0.36-2.55-0.09
                c-2.1-1.09-3.92-0.5-5.76,1.1C124.25,534.82,128.77,535.04,133.57,534.15z"/>
              <path class="red-brown"
                    d="M119.16,534.76c5.14,1.06,10.11,1.22,15.19,0.37C131.81,539.45,123.19,539.26,119.16,534.76z"/>
              <path class="red-brown" d="M133.57,534.15c-4.8,0.89-9.32,0.67-14.04-0.48c1.84-1.6,3.67-2.19,5.76-1.1c0.88,0.46,1.65,0.34,2.55,0.09
                C129.88,532.11,131.77,532.45,133.57,534.15z"/>
            </g>
            <g id="logo"  class="animate__animated animate__fadeInDown _3 abside">
              <path class="black" d="M426.18,28.51c0-12.19-9.2-21.04-21.88-21.04c-10.17,0-18.07,5.87-20.65,14.64
                c-4.93,2.38-8.59,6.51-10.33,11.68c-4.58,2.29-8.06,6.14-9.87,10.93c-0.64,0.4-1.26,0.83-1.85,1.29
                c-3.51-0.58-7.48-0.84-12.37-0.84c-15.06,0-28.69,8.05-34.9,20.33c-4.27,3.16-7.71,7.09-10.13,11.56
                c-4.26,3.15-7.71,7.07-10.14,11.53c-7.92,6.67-12.26,15.82-12.26,26.01c0,10.68,3.57,19.16,10.44,25.07l-0.95,1.05
                c-0.03,0.03-0.06,0.06-0.09,0.1c-0.95,1.06-1.7,2.24-2.26,3.49l-5.85,6.41c-0.03,0.03-0.06,0.06-0.08,0.09
                c-4.86,5.48-4.45,13.23,0.96,18.04c0.04,0.03,0.08,0.07,0.11,0.1c9.67,8.32,21.62,12.36,36.56,12.36c3.46,0,6.82-0.33,10.02-0.97
                c-0.33,1.16-0.51,2.4-0.51,3.68v11.75c0,7.28,5.7,12.98,12.98,12.98h12.93c15.38,0,27.96-6.97,34.86-19.21
                c3.31-2.82,6.07-6.19,8.25-10.06c4.37-3.25,7.98-7.47,10.61-12.4c10.03-7.48,15.73-19.88,15.73-34.47V51.68
                c0-3.86-1.61-7.28-4.2-9.64C424.39,38.46,426.18,33.77,426.18,28.51z"/>
              <g>
                <text transform="matrix(1 0 0 1 350.9349 163.9727)"
                      class="red black-stroke small-stroke lufga about-logo-letters">j</text>
                <text transform="matrix(1 0 0 1 359.1317 153.9766)"
                      class="yellow black-stroke small-stroke lufga about-logo-letters">j</text>
                <text transform="matrix(1 0 0 1 369.2919 142.3936)"
                      class="blue black-stroke small-stroke lufga about-logo-letters">j</text>
                <text transform="matrix(1 0 0 1 379.8636 130.0255)"
                      class="white black-stroke small-stroke lufga about-logo-letters">j</text>
              </g>
              <g>
                <text transform="matrix(1 0 0 1 283.6127 170.9493)"
                      class="red black-stroke small-stroke lufga about-logo-letters">s</text>
                <text transform="matrix(1 0 0 1 291.809 160.9542)"
                      class="yellow black-stroke small-stroke lufga about-logo-letters">s</text>
                <text transform="matrix(1 0 0 1 301.9716 149.3702)"
                      class="blue black-stroke small-stroke lufga about-logo-letters">s</text>
                <text transform="matrix(1 0 0 1 312.5428 137.002)"
                      class="white black-stroke small-stroke lufga about-logo-letters">s</text>
              </g>
            </g>
            <g id="circles2_7_" class="animate__animated animate__fadeInRight _3">
              <g>
                <circle class="red black-stroke small-stroke" cx="595.12" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="613.92" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="632.73" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="651.54" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="670.34" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="689.15" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="707.95" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="726.76" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="745.57" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="764.37" cy="584.64" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="783.18" cy="584.64" r="6.59"/>
              </g>
              <g>
                <circle class="red black-stroke small-stroke" cx="622.63" cy="614.93" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="641.44" cy="614.93" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="660.24" cy="614.93" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="679.05" cy="614.93" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="697.86" cy="614.93" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="716.66" cy="614.93" r="6.59"/>
              </g>
              <g>
                <circle class="red black-stroke small-stroke" cx="555.53" cy="645.22" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="574.34" cy="645.22" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="593.14" cy="645.22" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="611.95" cy="645.22" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="630.75" cy="645.22" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="649.56" cy="645.22" r="6.59"/>
              </g>
              <g>
                <circle class="red black-stroke small-stroke" cx="643.92" cy="554.35" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="662.73" cy="554.35" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="681.54" cy="554.35" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="700.34" cy="554.35" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="719.15" cy="554.35" r="6.59"/>
                <circle class="red black-stroke small-stroke" cx="737.95" cy="554.35" r="6.59"/>
              </g>
            </g>
          </g>
        </svg>
        <h1 class="title is-1 has-text-grey-dark is-highlighted red-highlight is-align-self-flex-start animate__animated animate__fadeInDown _2">
          about me</h1>
      </div>
    </section>
  </b-carousel-item>
</template>

<script>
export default {
  name: "About"
}
</script>
